import React, { Component } from "react";

import {ReactComponent as WebsiteLogo} from './assets/svg/website.svg';
import {ReactComponent as GitlabLogo} from './assets/svg/gitlab.svg';
import {ReactComponent as LBRYLogo} from './assets/svg/lbry.svg';
import {ReactComponent as TelegramLogo} from './assets/svg/telegram.svg';
import {ReactComponent as EmailLogo} from './assets/svg/envelope.svg';
import {ReactComponent as MatrixLogo} from './assets/svg/matrix.svg';
import {ReactComponent as MeteoblueLogo} from './assets/svg/meteoblue.svg';

import BurnairLogo from './assets/webp/burnair.webp';
import VinstaLogo from './assets/webp/vinstagram-white.webp';
import NCLogo from './assets/webp/nextcloud.webp';


import anime from 'animejs/lib/anime.es.js';

import './App.scss';

class App extends Component {
    componentDidMount() {
		const svgPath = document.querySelectorAll('.path');

		anime({
			targets: svgPath,
			loop: false,
			direction: 'alternate',
			strokeDashoffset: [anime.setDashoffset, 0],
			easing: 'easeInOutSine',
			duration: 1200,
			delay: (el, i) => { return i * 650 }
		});

		const background = document.querySelector('body');
		let now = background.style.background
		console.log(now)

    }

    render() {
		return (
			<div className="App">
				<header className="App-header">
					<WebsiteLogo className="App-logo"/>

					<h1>Vincent van der Wal</h1>
					<h2>Webdeveloper</h2>
					<div className="links">
						<a href="https://gitlab.servert.ch/explore" aria-label="Gitlab">
							<GitlabLogo />
						</a>
						<a href="https://matrix.to/#/@vincent:tjet.nl" aria-label="Matrix Synapse">
							<MatrixLogo />
						</a>
						<a href="https://t.me/vinnie2" aria-label="Telegram">
							<TelegramLogo />
						</a>
						<a href="mailto:info@vincentvanderwal.nl" aria-label="Email">
							<EmailLogo />
						</a>
						<a href="https://vinstagram.nl" aria-label="Vinstagram">
							<img width="130px" height="49px" alt="Vinsta logo" src={VinstaLogo} />
						</a>
						<a href="https://servert.ch/" aria-label="Nextcloud">
							<img width="130px" height="92px" alt="Nextcloud logo" src={NCLogo} />
						</a>
						<a href="https://open.lbry.com/@vinnie:a" aria-label="LRBY">
							<LBRYLogo />
						</a>
					</div>
				</header>
				<div className="partners" id="partners">
					<h2>Partners</h2>
					<div className="links">
						<a href="https://meteoblue.com/" aria-label="meteoblue">
							<MeteoblueLogo />
						</a>
						<a style={{marginTop: "-9px"}} href="https://burnair.ch/" aria-label="burnair">
							<img width="140px" height="60px" alt="burnair logo" src={BurnairLogo} />
						</a>
					</div>
				</div>
			</div>
      	);
    }
}

export default App;
